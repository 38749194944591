import React from 'react';
import Header from '../components/sections/Header';
import Hero from '../components/sections/Hero';
import Services from '../components/sections/Services';
import Process from '../components/sections/Process';
import Testimonials from '../components/sections/Testimonials';
import Pricing from '../components/sections/Pricing';
import Contact from '../components/sections/Contact';
import Footer from '../components/sections/Footer';

const Home: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-[#111111] transition-colors duration-300">
      <Header />
      <Hero />
      <Services />
      <Process />
      <Testimonials />
      <Pricing />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;