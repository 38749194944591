import React from 'react';
import {
  CpuChipIcon,
  Square2StackIcon,
  ChartBarIcon,
} from '@heroicons/react/24/outline';

const Services: React.FC = () => {
  return (
    <section className="py-20 bg-white dark:bg-[#151515] transition-colors duration-300">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-gray-900 dark:text-white">
          Nossos Serviços
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-gray-50 dark:bg-[#1A1A1A] p-8 rounded-2xl border border-gray-100 dark:border-[#333333] hover:border-neon-green/50 transition-all duration-300 group shadow-sm">
            <div className="text-neon-green mb-6 w-12 h-12 flex items-center justify-center rounded-full bg-neon-green/10 group-hover:bg-neon-green/20 transition-all duration-300">
              <CpuChipIcon className="h-6 w-6" />
            </div>
            <h3 className="text-neon-green text-xl font-bold mb-4">Inovação Automatizada</h3>
            <p className="text-gray-600 dark:text-gray-400">Identificamos gargalos e automatizamos processos para otimizar o tempo e recursos da sua empresa.</p>
          </div>
          <div className="bg-gray-50 dark:bg-[#1A1A1A] p-8 rounded-2xl border border-gray-100 dark:border-[#333333] hover:border-neon-green/50 transition-all duration-300 group shadow-sm">
            <div className="text-neon-green mb-6 w-12 h-12 flex items-center justify-center rounded-full bg-neon-green/10 group-hover:bg-neon-green/20 transition-all duration-300">
              <Square2StackIcon className="h-6 w-6" />
            </div>
            <h3 className="text-neon-green text-xl font-bold mb-4">Design Estratégico</h3>
            <p className="text-gray-600 dark:text-gray-400">Soluções visuais que não apenas impressionam, mas convertem.</p>
          </div>
          <div className="bg-gray-50 dark:bg-[#1A1A1A] p-8 rounded-2xl border border-gray-100 dark:border-[#333333] hover:border-neon-green/50 transition-all duration-300 group shadow-sm">
            <div className="text-neon-green mb-6 w-12 h-12 flex items-center justify-center rounded-full bg-neon-green/10 group-hover:bg-neon-green/20 transition-all duration-300">
              <ChartBarIcon className="h-6 w-6" />
            </div>
            <h3 className="text-neon-green text-xl font-bold mb-4">Marketing Digital</h3>
            <p className="text-gray-600 dark:text-gray-400">Estratégias personalizadas para alavancar sua marca e atrair o público certo.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
