import React, { useState, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import Modal from './Modal';
import Button from './Button';
import { useTheme } from '../contexts/ThemeContext';
import api from '../services/api';

interface Lead {
  id: string;
  nome: string;
  email: string;
  telefone?: string;
  mensagem?: string;
}

interface LeadSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (selectedLeads: string[]) => void;
  currentLeads: Lead[];
}

const LeadSelectionModal: React.FC<LeadSelectionModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  currentLeads,
}) => {
  const { isDarkMode } = useTheme();
  const [leads, setLeads] = useState<Lead[]>([]);
  const [selectedLeads, setSelectedLeads] = useState<Set<string>>(new Set());
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchLeads();
      // Initialize selected leads with current leads
      setSelectedLeads(new Set(currentLeads.map(lead => lead.id)));
    }
  }, [isOpen, currentLeads]);

  const fetchLeads = async () => {
    setLoading(true);
    try {
      const response = await api.get('/leads');
      setLeads(response.data);
    } catch (error) {
      console.error('Erro ao buscar leads:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredLeads = leads.filter(
    lead =>
      lead.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lead.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (lead.telefone && lead.telefone.includes(searchTerm))
  );

  const handleToggleLead = (leadId: string) => {
    const newSelected = new Set(selectedLeads);
    if (newSelected.has(leadId)) {
      newSelected.delete(leadId);
    } else {
      newSelected.add(leadId);
    }
    setSelectedLeads(newSelected);
  };

  const handleConfirm = () => {
    onConfirm(Array.from(selectedLeads));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Selecionar Leads">
      <div className="space-y-6">
        {/* Search Bar */}
        <div className="relative">
          <FiSearch className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
          <input
            type="text"
            placeholder="Buscar leads..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={`w-full pl-10 pr-4 py-2 rounded-xl ${
              isDarkMode
                ? 'bg-[#252525] text-white border-gray-700'
                : 'bg-white text-gray-900 border-gray-300'
            } focus:ring-2 focus:ring-neon-green focus:border-transparent`}
          />
        </div>

        {/* Leads List */}
        <div className={`max-h-96 overflow-y-auto rounded-xl ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} border`}>
          {loading ? (
            <div className="p-4 text-center">Carregando...</div>
          ) : filteredLeads.length === 0 ? (
            <div className="p-4 text-center">Nenhum lead encontrado</div>
          ) : (
            filteredLeads.map((lead) => (
              <div
                key={lead.id}
                className={`flex items-center space-x-4 p-4 ${
                  isDarkMode
                    ? 'hover:bg-[#252525] border-gray-700'
                    : 'hover:bg-gray-50 border-gray-200'
                } border-b last:border-b-0 cursor-pointer`}
                onClick={() => handleToggleLead(lead.id)}
              >
                <input
                  type="checkbox"
                  checked={selectedLeads.has(lead.id)}
                  onChange={() => handleToggleLead(lead.id)}
                  className="h-4 w-4 text-neon-green focus:ring-neon-green rounded"
                />
                <div className="flex-1">
                  <h4 className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {lead.nome}
                  </h4>
                  <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    {lead.email}
                  </p>
                  {lead.telefone && (
                    <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      {lead.telefone}
                    </p>
                  )}
                </div>
              </div>
            ))
          )}
        </div>

        {/* Actions */}
        <div className="flex justify-end space-x-4">
          <Button type="button" onClick={onClose} variant="secondary">
            Cancelar
          </Button>
          <Button type="button" onClick={handleConfirm}>
            Confirmar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LeadSelectionModal;
