import api from './api';
import evoApi from './evoApi';

interface CreateInstanceParams {
  instanceName: string;
  token: string;
  number?: string;
  qrcode?: boolean;
  integration?: string;
  reject_call?: boolean;
  msgCall?: string;
  groupsIgnore?: boolean;
  alwaysOnline?: boolean;
  readMessages?: boolean;
  readStatus?: boolean;
  syncFullHistory?: boolean;
  webhookUrl?: string;
  webhookByEvents?: boolean;
  webhookBase64?: boolean;
  webhookEvents?: string[];
}

interface SendMessageParams {
  instance: string;
  number: string;
  text: string;
}

export const whatsappService = {
  getAllInstances: async () => {
    try {
      const response = await evoApi.get('/instance/fetchInstances');
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar instâncias:', error);
      throw error;
    }
  },

  createInstance: async (params: CreateInstanceParams) => {
    try {
      const response = await evoApi.post('/instance/create', params);
      return response.data;
    } catch (error) {
      console.error('Erro ao criar instância:', error);
      throw error;
    }
  },

  connectInstance: async (instanceName: string) => {
    try {
      const response = await evoApi.post(`/instance/connect/${instanceName}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao conectar instância:', error);
      throw error;
    }
  },

  disconnectInstance: async (instanceName: string) => {
    try {
      const response = await evoApi.post(`/instance/disconnect/${instanceName}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao desconectar instância:', error);
      throw error;
    }
  },

  deleteInstance: async (instanceName: string) => {
    try {
      const response = await evoApi.delete(`/instance/delete/${instanceName}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar instância:', error);
      throw error;
    }
  },

  sendMessage: async (params: SendMessageParams) => {
    try {
      const response = await evoApi.post(`/message/sendText/${params.instance}`, {
        number: params.number,
        text: params.text
      });
      return response.data;
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
      throw error;
    }
  }
};