import React from 'react';

const Testimonials: React.FC = () => {
  return (
    <section className="py-20 bg-gray-50 dark:bg-[#111111] transition-colors duration-300">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-gray-900 dark:text-white">
          O que nossos clientes dizem
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white dark:bg-[#1A1A1A] p-8 rounded-2xl border border-gray-100 dark:border-[#333333] hover:border-neon-green/50 transition-all duration-300 shadow-sm">
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              "A Moxxy transformou completamente nossa presença digital. O resultado superou todas as expectativas!"
            </p>
            <div>
              <h4 className="font-bold text-gray-900 dark:text-white">Ana Silva</h4>
              <p className="text-gray-600 dark:text-gray-400">CEO, TechStart</p>
            </div>
          </div>
          <div className="bg-white dark:bg-[#1A1A1A] p-8 rounded-2xl border border-gray-100 dark:border-[#333333] hover:border-neon-green/50 transition-all duration-300 shadow-sm">
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              "Profissionalismo e inovação em cada etapa do projeto. Recomendo fortemente!"
            </p>
            <div>
              <h4 className="font-bold text-gray-900 dark:text-white">Carlos Santos</h4>
              <p className="text-gray-600 dark:text-gray-400">Diretor, InnovateLab</p>
            </div>
          </div>
          <div className="bg-white dark:bg-[#1A1A1A] p-8 rounded-2xl border border-gray-100 dark:border-[#333333] hover:border-neon-green/50 transition-all duration-300 shadow-sm">
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              "A automação implementada pela Moxxy aumentou nossa produtividade em 150%. Incrível!"
            </p>
            <div>
              <h4 className="font-bold text-gray-900 dark:text-white">Marina Costa</h4>
              <p className="text-gray-600 dark:text-gray-400">COO, DataFlow</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
