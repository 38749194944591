import React, { useEffect, useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { whatsappService } from '../../services/whatsappService';
import { leadsService, Lead } from '../../services/leadsService';
import { FiRefreshCw, FiPower, FiTrash2, FiX, FiMessageCircle, FiUsers, FiClock, FiSettings, FiSearch, FiPlus } from 'react-icons/fi';

interface Instance {
  id: string;
  name: string;
  connectionStatus: string;
  ownerJid: string;
  profileName: string;
  profilePicUrl: string | null;
  number: string | null;
  createdAt: string;
  updatedAt: string;
  _count: {
    Message: number;
    Contact: number;
    Chat: number;
  };
  Setting: {
    rejectCall: boolean;
    msgCall: string;
    alwaysOnline: boolean;
    readMessages: boolean;
  };
}

interface CreateInstanceFormData {
  instanceName: string;
  token: string;
  number: string;
  integration: string;
}

interface SendMessageFormData {
  instance: string;
  number: string;
  text: string;
}

const WhatsApp: React.FC = () => {
  const [instances, setInstances] = useState<Instance[]>([]);
  const [filteredInstances, setFilteredInstances] = useState<Instance[]>([]);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSendMessageModalOpen, setIsSendMessageModalOpen] = useState(false);
  const [instanceToDelete, setInstanceToDelete] = useState<Instance | null>(null);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [createFormData, setCreateFormData] = useState<CreateInstanceFormData>({
    instanceName: '',
    token: '',
    number: '',
    integration: 'WHATSAPP-BAILEYS'
  });
  const [sendMessageForm, setSendMessageForm] = useState<SendMessageFormData>({
    instance: '',
    number: '',
    text: ''
  });
  const { isDarkMode } = useTheme();

  const fetchInstances = async () => {
    try {
      setLoading(true);
      const response = await whatsappService.getAllInstances();
      console.log('Resposta da API:', response);
      setInstances(response || []);
      setFilteredInstances(response || []);
    } catch (err) {
      console.error('Erro detalhado:', err);
      setError('Erro ao carregar instâncias do WhatsApp');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInstances();
  }, []);

  const fetchLeads = async () => {
    try {
      const response = await leadsService.getAllLeads();
      setLeads(response);
    } catch (err) {
      console.error('Erro ao carregar leads:', err);
      setError('Erro ao carregar leads');
    }
  };

  useEffect(() => {
    if (isSendMessageModalOpen) {
      fetchLeads();
    }
  }, [isSendMessageModalOpen]);

  useEffect(() => {
    if (selectedLead?.telefone) {
      setSendMessageForm(prev => ({
        ...prev,
        number: leadsService.formatPhoneNumber(selectedLead.telefone)
      }));
    }
  }, [selectedLead]);

  useEffect(() => {
    const filtered = instances.filter((instance) => {
      const searchFields = [
        instance.name?.toLowerCase() || '',
        instance.profileName?.toLowerCase() || '',
        instance.number?.toLowerCase() || '',
        instance.ownerJid?.toLowerCase() || ''
      ].filter(Boolean);

      const searchLower = searchTerm.toLowerCase();
      return searchFields.some(field => field.includes(searchLower));
    });
    setFilteredInstances(filtered);
  }, [searchTerm, instances]);

  const handleRefresh = () => {
    fetchInstances();
  };

  const handleToggleConnection = async (instance: Instance) => {
    try {
      setActionInProgress(true);
      const result = instance.connectionStatus === 'open'
        ? await whatsappService.disconnectInstance(instance.id)
        : await whatsappService.connectInstance(instance.id);

      if (result.success) {
        await fetchInstances();
      } else {
        setError(result.error || 'Erro ao alterar estado da conexão');
      }
    } catch (err) {
      setError('Erro ao alterar estado da conexão');
    } finally {
      setActionInProgress(false);
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleCreateInstance = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setActionInProgress(true);
      await whatsappService.createInstance({
        ...createFormData,
        qrcode: true,
        reject_call: true,
        groupsIgnore: true,
        alwaysOnline: true,
        readMessages: true,
        readStatus: true,
        syncFullHistory: true,
        webhookByEvents: true
      });
      setIsCreateModalOpen(false);
      setCreateFormData({
        instanceName: '',
        token: '',
        number: '',
        integration: 'WHATSAPP-BAILEYS'
      });
      await fetchInstances();
    } catch (err) {
      setError('Erro ao criar instância');
    } finally {
      setActionInProgress(false);
    }
  };

  const handleDeleteInstance = async (instance: Instance) => {
    setInstanceToDelete(instance);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (!instanceToDelete) return;

    try {
      setActionInProgress(true);
      await whatsappService.deleteInstance(instanceToDelete.name);
      setIsDeleteModalOpen(false);
      setInstanceToDelete(null);
      await fetchInstances();
    } catch (err) {
      setError('Erro ao excluir instância');
    } finally {
      setActionInProgress(false);
    }
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setActionInProgress(true);
      await whatsappService.sendMessage(sendMessageForm);
      setIsSendMessageModalOpen(false);
      setSendMessageForm({
        instance: '',
        number: '',
        text: ''
      });
      setSelectedLead(null);
    } catch (err) {
      setError('Erro ao enviar mensagem');
    } finally {
      setActionInProgress(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[60vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-neon-green"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className={`text-3xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Instâncias do WhatsApp
          </h1>
          <p className={`mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            Gerencie suas conexões do WhatsApp
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <div className={`relative ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5" />
            <input
              type="text"
              placeholder="Buscar instâncias..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`pl-10 pr-4 py-2 rounded-lg border ${
                isDarkMode
                  ? 'bg-[#252525] border-gray-700 text-white placeholder-gray-500'
                  : 'bg-white border-gray-300 text-gray-900 placeholder-gray-400'
              } focus:outline-none focus:ring-2 focus:ring-neon-green w-64`}
            />
          </div>
          <button
            onClick={handleRefresh}
            disabled={actionInProgress}
            className={`p-2 rounded-lg transition-colors duration-200 ${
              isDarkMode
                ? 'text-gray-300 hover:text-white hover:bg-gray-800'
                : 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
            } ${actionInProgress ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <FiRefreshCw className={`w-5 h-5 ${actionInProgress ? 'animate-spin' : ''}`} />
          </button>
          <button
            onClick={() => setIsCreateModalOpen(true)}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 bg-neon-green text-white hover:bg-neon-green/90 transition-colors duration-200`}
          >
            <FiPlus className="w-5 h-5" />
            <span>Criar Instância</span>
          </button>
          <button
            onClick={() => setIsSendMessageModalOpen(true)}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200`}
          >
            <FiMessageCircle className="w-5 h-5" />
            <span>Enviar Mensagem</span>
          </button>
        </div>
      </div>

      {error && (
        <div className="mb-6 flex items-center justify-between bg-red-500/10 border border-red-500 text-red-500 px-4 py-3 rounded-lg">
          <span>{error}</span>
          <button
            onClick={() => setError(null)}
            className="text-red-500 hover:text-red-600 transition-colors duration-200"
          >
            <FiX className="w-5 h-5" />
          </button>
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
        {filteredInstances.length > 0 ? (
          filteredInstances.map((instance) => (
            <div
              key={instance.id}
              className={`${
                isDarkMode
                  ? 'bg-[#1a1a1a] border-gray-800 hover:bg-[#252525]'
                  : 'bg-white border-gray-200 hover:bg-gray-50'
              } border rounded-xl p-6 transition-all duration-200`}
            >
              {/* Cabeçalho do Card */}
              <div className="flex items-center mb-6">
                <div className="relative">
                  {instance.profilePicUrl ? (
                    <img
                      src={instance.profilePicUrl}
                      alt={instance.name}
                      className="w-16 h-16 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-16 h-16 rounded-full bg-gradient-to-br from-purple-500 to-pink-500 flex items-center justify-center">
                      <span className="text-2xl text-white font-bold">{instance.name[0]}</span>
                    </div>
                  )}
                  <div className={`absolute -bottom-1 -right-1 w-4 h-4 rounded-full border-2 ${
                    isDarkMode
                      ? 'border-[#1a1a1a]'
                      : 'border-white'
                  } ${
                    instance.connectionStatus === 'open' ? 'bg-green-500' : 'bg-red-500'
                  }`}></div>
                </div>
                <div className="ml-4 flex-1">
                  <h3 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {instance.name}
                  </h3>
                  <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    {instance.number || (instance.ownerJid ? instance.ownerJid.split('@')[0] : 'Número não disponível')}
                  </p>
                </div>
              </div>

              {/* Estatísticas */}
              <div className="grid grid-cols-3 gap-4 mb-6">
                <div className={`text-center p-3 rounded-lg ${
                  isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
                }`}>
                  <FiMessageCircle className="w-5 h-5 mx-auto mb-1 text-blue-500" />
                  <div className={`text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {instance._count.Message.toLocaleString()}
                  </div>
                  <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Mensagens
                  </div>
                </div>
                <div className={`text-center p-3 rounded-lg ${
                  isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
                }`}>
                  <FiUsers className="w-5 h-5 mx-auto mb-1 text-green-500" />
                  <div className={`text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {instance._count.Contact.toLocaleString()}
                  </div>
                  <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Contatos
                  </div>
                </div>
                <div className={`text-center p-3 rounded-lg ${
                  isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
                }`}>
                  <FiMessageCircle className="w-5 h-5 mx-auto mb-1 text-purple-500" />
                  <div className={`text-lg font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {instance._count.Chat.toLocaleString()}
                  </div>
                  <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    Chats
                  </div>
                </div>
              </div>

              {/* Configurações */}
              <div className={`mb-6 p-4 rounded-lg text-sm ${
                isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'
              }`}>
                <div className="flex items-center mb-2">
                  <FiSettings className={`w-4 h-4 mr-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                  <span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    Configurações
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <div className={`flex items-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    <div className={`w-2 h-2 rounded-full mr-2 ${
                      instance.Setting.rejectCall ? 'bg-red-500' : 'bg-green-500'
                    }`}></div>
                    Rejeitar Chamadas
                  </div>
                  <div className={`flex items-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    <div className={`w-2 h-2 rounded-full mr-2 ${
                      instance.Setting.alwaysOnline ? 'bg-green-500' : 'bg-gray-500'
                    }`}></div>
                    Sempre Online
                  </div>
                  <div className={`flex items-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                    <div className={`w-2 h-2 rounded-full mr-2 ${
                      instance.Setting.readMessages ? 'bg-green-500' : 'bg-gray-500'
                    }`}></div>
                    Ler Mensagens
                  </div>
                </div>
              </div>

              {/* Datas */}
              <div className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-400'} mb-4`}>
                <div className="flex items-center">
                  <FiClock className="w-3 h-3 mr-1" />
                  Criado em: {formatDate(instance.createdAt)}
                </div>
                <div className="flex items-center mt-1">
                  <FiClock className="w-3 h-3 mr-1" />
                  Atualizado em: {formatDate(instance.updatedAt)}
                </div>
              </div>

              {/* Ações */}
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => handleToggleConnection(instance)}
                  disabled={actionInProgress}
                  className={`p-2 rounded-lg transition-colors duration-200 ${
                    isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'
                  } ${actionInProgress ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  <FiPower
                    className={`w-5 h-5 ${
                      instance.connectionStatus === 'open' ? 'text-green-500' : 'text-red-500'
                    }`}
                  />
                </button>
                <button
                  onClick={() => handleDeleteInstance(instance)}
                  disabled={actionInProgress}
                  className={`p-2 rounded-lg transition-colors duration-200 ${
                    isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'
                  } ${actionInProgress ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  <FiTrash2 className="w-5 h-5 text-red-500" />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className={`col-span-3 text-center py-8 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            {searchTerm
              ? 'Nenhuma instância encontrada para esta busca'
              : 'Nenhuma instância encontrada'}
          </div>
        )}
      </div>
      {isCreateModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className={`${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-white'} rounded-xl p-6 w-full max-w-md`}>
            <div className="flex justify-between items-center mb-6">
              <h2 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Criar Nova Instância
              </h2>
              <button
                onClick={() => setIsCreateModalOpen(false)}
                className={`p-2 rounded-lg ${
                  isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'
                }`}
              >
                <FiX className="w-5 h-5" />
              </button>
            </div>
            <form onSubmit={handleCreateInstance}>
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="instanceName"
                    className={`block text-sm font-medium mb-1 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    Nome da Instância
                  </label>
                  <input
                    type="text"
                    id="instanceName"
                    value={createFormData.instanceName}
                    onChange={(e) =>
                      setCreateFormData((prev) => ({ ...prev, instanceName: e.target.value }))
                    }
                    className={`w-full px-4 py-2 rounded-lg border ${
                      isDarkMode
                        ? 'bg-[#252525] border-gray-700 text-white'
                        : 'bg-white border-gray-300 text-gray-900'
                    } focus:outline-none focus:ring-2 focus:ring-neon-green`}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="token"
                    className={`block text-sm font-medium mb-1 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    Token
                  </label>
                  <input
                    type="text"
                    id="token"
                    value={createFormData.token}
                    onChange={(e) =>
                      setCreateFormData((prev) => ({ ...prev, token: e.target.value }))
                    }
                    className={`w-full px-4 py-2 rounded-lg border ${
                      isDarkMode
                        ? 'bg-[#252525] border-gray-700 text-white'
                        : 'bg-white border-gray-300 text-gray-900'
                    } focus:outline-none focus:ring-2 focus:ring-neon-green`}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="number"
                    className={`block text-sm font-medium mb-1 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    Número
                  </label>
                  <input
                    type="text"
                    id="number"
                    value={createFormData.number}
                    onChange={(e) =>
                      setCreateFormData((prev) => ({ ...prev, number: e.target.value }))
                    }
                    className={`w-full px-4 py-2 rounded-lg border ${
                      isDarkMode
                        ? 'bg-[#252525] border-gray-700 text-white'
                        : 'bg-white border-gray-300 text-gray-900'
                    } focus:outline-none focus:ring-2 focus:ring-neon-green`}
                    required
                  />
                </div>
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setIsCreateModalOpen(false)}
                  className={`px-4 py-2 rounded-lg ${
                    isDarkMode
                      ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  disabled={actionInProgress}
                  className="px-4 py-2 rounded-lg bg-neon-green text-white hover:bg-neon-green/90 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {actionInProgress ? 'Criando...' : 'Criar'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Modal de Confirmação de Exclusão */}
      {isDeleteModalOpen && instanceToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className={`${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-white'} rounded-xl p-6 w-full max-w-md`}>
            <div className="flex justify-between items-center mb-6">
              <h2 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Confirmar Exclusão
              </h2>
              <button
                onClick={() => {
                  setIsDeleteModalOpen(false);
                  setInstanceToDelete(null);
                }}
                className={`p-2 rounded-lg ${
                  isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'
                }`}
              >
                <FiX className="w-5 h-5" />
              </button>
            </div>
            <p className={`mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Tem certeza que deseja excluir a instância "{instanceToDelete.name}"? Esta ação não pode ser desfeita.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => {
                  setIsDeleteModalOpen(false);
                  setInstanceToDelete(null);
                }}
                className={`px-4 py-2 rounded-lg ${
                  isDarkMode
                    ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
              >
                Cancelar
              </button>
              <button
                onClick={confirmDelete}
                disabled={actionInProgress}
                className="px-4 py-2 rounded-lg bg-red-500 text-white hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {actionInProgress ? 'Excluindo...' : 'Excluir'}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Modal de Envio de Mensagem */}
      {isSendMessageModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className={`${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-white'} rounded-xl p-6 w-full max-w-md`}>
            <div className="flex justify-between items-center mb-6">
              <h2 className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                Enviar Mensagem
              </h2>
              <button
                onClick={() => {
                  setIsSendMessageModalOpen(false);
                  setSendMessageForm({
                    instance: '',
                    number: '',
                    text: ''
                  });
                  setSelectedLead(null);
                }}
                className={`p-2 rounded-lg ${
                  isDarkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100'
                }`}
              >
                <FiX className="w-5 h-5" />
              </button>
            </div>
            <form onSubmit={handleSendMessage}>
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="instance"
                    className={`block text-sm font-medium mb-1 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    Instância
                  </label>
                  <select
                    id="instance"
                    value={sendMessageForm.instance}
                    onChange={(e) =>
                      setSendMessageForm((prev) => ({ ...prev, instance: e.target.value }))
                    }
                    className={`w-full px-4 py-2 rounded-lg border ${
                      isDarkMode
                        ? 'bg-[#252525] border-gray-700 text-white'
                        : 'bg-white border-gray-300 text-gray-900'
                    } focus:outline-none focus:ring-2 focus:ring-neon-green`}
                    required
                  >
                    <option value="">Selecione uma instância</option>
                    {instances
                      .filter((instance) => instance.connectionStatus === 'open')
                      .map((instance) => (
                        <option key={instance.id} value={instance.name}>
                          {instance.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="lead"
                    className={`block text-sm font-medium mb-1 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    Lead
                  </label>
                  <select
                    id="lead"
                    value={selectedLead?.id || ''}
                    onChange={(e) => {
                      const lead = leads.find(l => l.id === e.target.value);
                      setSelectedLead(lead || null);
                    }}
                    className={`w-full px-4 py-2 rounded-lg border ${
                      isDarkMode
                        ? 'bg-[#252525] border-gray-700 text-white'
                        : 'bg-white border-gray-300 text-gray-900'
                    } focus:outline-none focus:ring-2 focus:ring-neon-green`}
                    required
                  >
                    <option value="">Selecione um lead</option>
                    {leads.map((lead) => (
                      <option key={lead.id} value={lead.id}>
                        {lead.nome} - {lead.telefone}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="text"
                    className={`block text-sm font-medium mb-1 ${
                      isDarkMode ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    Texto
                  </label>
                  <textarea
                    id="text"
                    value={sendMessageForm.text}
                    onChange={(e) =>
                      setSendMessageForm((prev) => ({ ...prev, text: e.target.value }))
                    }
                    className={`w-full px-4 py-2 rounded-lg border ${
                      isDarkMode
                        ? 'bg-[#252525] border-gray-700 text-white'
                        : 'bg-white border-gray-300 text-gray-900'
                    } focus:outline-none focus:ring-2 focus:ring-neon-green`}
                    required
                    rows={4}
                  />
                </div>
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setIsSendMessageModalOpen(false);
                    setSendMessageForm({
                      instance: '',
                      number: '',
                      text: ''
                    });
                    setSelectedLead(null);
                  }}
                  className={`px-4 py-2 rounded-lg ${
                    isDarkMode
                      ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  disabled={actionInProgress}
                  className="px-4 py-2 rounded-lg bg-neon-green text-white hover:bg-neon-green/90 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {actionInProgress ? 'Enviando...' : 'Enviar'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhatsApp;