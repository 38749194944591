import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { FiMenu, FiX, FiLogOut, FiHome, FiUser, FiUsers, FiTool, FiTarget, FiLayers, FiMessageCircle } from 'react-icons/fi';
import { useTheme } from '../contexts/ThemeContext';

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode } = useTheme();

  // Close sidebar when clicking outside on mobile
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const sidebar = document.getElementById('sidebar');
      const menuButton = document.getElementById('menu-button');
      if (sidebarOpen && sidebar && !sidebar.contains(event.target as Node) && menuButton && !menuButton.contains(event.target as Node)) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [sidebarOpen]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const menuItems = [
    { path: '/dashboard', label: 'Dashboard', icon: <FiHome className="w-5 h-5" /> },
    { path: '/dashboard/profile', label: 'Perfil', icon: <FiUser className="w-5 h-5" /> },
    ...(user?.nivel_acesso === '1' ? [
      { path: '/dashboard/users', label: 'Usuários', icon: <FiUsers className="w-5 h-5" /> },
      { path: '/dashboard/services', label: 'Serviços', icon: <FiTool className="w-5 h-5" /> },
      { path: '/dashboard/leads', label: 'Leads', icon: <FiTarget className="w-5 h-5" /> },
      { path: '/dashboard/segments', label: 'Segmentos', icon: <FiLayers className="w-5 h-5" /> },
      { path: '/dashboard/whatsapp', label: 'WhatsApp', icon: <FiMessageCircle className="w-5 h-5" /> },
    ] : []),
  ];

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-[#111111]' : 'bg-gray-50'} transition-colors duration-300`}>
      {/* Navigation Bar */}
      <nav className={`fixed w-full top-0 z-50 ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-white'} border-b ${isDarkMode ? 'border-gray-800' : 'border-gray-200'} transition-colors duration-300`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <button
                id="menu-button"
                onClick={() => setSidebarOpen(!sidebarOpen)}
                className="inline-flex items-center justify-center p-2 rounded-lg text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-neon-green lg:hidden"
              >
                {sidebarOpen ? (
                  <FiX className="w-6 h-6" />
                ) : (
                  <FiMenu className="w-6 h-6" />
                )}
              </button>
              <Link to="/dashboard" className="flex items-center space-x-3 ml-2 lg:ml-0">
                <span className={`text-xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Dashboard
                </span>
              </Link>
            </div>
            <div className="flex items-center space-x-4">
              <div className={`hidden md:flex items-center space-x-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                <FiUser className="w-4 h-4" />
                <span>{user?.nome}</span>
              </div>
              <button
                onClick={handleLogout}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-neon-green hover:bg-neon-green/90 transition-colors duration-300"
              >
                <FiLogOut className="w-4 h-4 mr-2" />
                Sair
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="flex min-h-screen pt-16">
        {/* Sidebar */}
        <aside
          id="sidebar"
          className={`${
            sidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } fixed inset-y-0 left-0 transform lg:translate-x-0 lg:relative lg:inset-auto z-40
          w-64 ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-white'} ${isDarkMode ? 'border-gray-800' : 'border-gray-200'} 
          border-r transition-transform duration-300 ease-in-out lg:transition-none h-[calc(100vh-4rem)]`}
        >
          <div className="h-full px-3 py-4 overflow-y-auto">
            <ul className="space-y-2">
              {menuItems.map((item) => (
                <li key={item.path}>
                  <Link
                    to={item.path}
                    onClick={() => setSidebarOpen(false)}
                    className={`flex items-center p-3 rounded-xl transition-all duration-200 ${
                      location.pathname === item.path
                        ? 'bg-neon-green text-black font-medium shadow-lg shadow-neon-green/20'
                        : `${isDarkMode ? 'text-gray-300 hover:bg-gray-800' : 'text-gray-700 hover:bg-gray-100'}`
                    }`}
                  >
                    <span className="mr-3">{item.icon}</span>
                    <span>{item.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </aside>

        {/* Overlay */}
        {sidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 transition-opacity lg:hidden z-30"
            onClick={() => setSidebarOpen(false)}
          />
        )}

        {/* Main Content */}
        <main className="flex-1 overflow-x-hidden min-h-[calc(100vh-4rem)]">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
