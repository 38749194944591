import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

interface User {
  id: string;
  nome: string;
  email: string;
  nivel_acesso: string;
  telefone?: string;
  empresa?: string;
  endereco?: string;
  created_at?: string;
  updated_at?: string;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  login: (token: string, userData: User, refreshToken: string) => void;
  logout: () => void;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const checkTokenExpiration = (token: string) => {
    try {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = tokenData.exp * 1000; // Converter para milissegundos
      // Renovar token se estiver próximo de expirar (24h antes)
      const shouldRefresh = (expirationTime - Date.now()) < (24 * 60 * 60 * 1000);
      return { isValid: Date.now() < expirationTime, shouldRefresh };
    } catch {
      return { isValid: false, shouldRefresh: false };
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    const savedUser = localStorage.getItem('user');
    
    if (token && savedUser) {
      // Verifica se o token está válido
      const { isValid, shouldRefresh } = checkTokenExpiration(token);
      if (isValid) {
        const userData = JSON.parse(savedUser);
        setUser(userData);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        
        // Se o token estiver próximo de expirar e tivermos um refresh token, renovamos
        if (shouldRefresh && refreshToken) {
          axios.post('/auth/refresh-token', { refreshToken })
            .then(response => {
              const { token: newToken, refreshToken: newRefreshToken } = response.data;
              localStorage.setItem('token', newToken);
              localStorage.setItem('refreshToken', newRefreshToken);
              axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
            })
            .catch(() => {
              // Se falhar em renovar, mantém o token atual
              console.log('Falha ao renovar token');
            });
        }
      } else {
        // Se o token estiver expirado, tenta usar o refresh token
        if (refreshToken) {
          axios.post('/auth/refresh-token', { refreshToken })
            .then(response => {
              const { token: newToken, refreshToken: newRefreshToken, user: userData } = response.data;
              login(newToken, userData, newRefreshToken);
            })
            .catch(() => {
              // Se falhar, faz logout
              logout();
            });
        } else {
          logout();
        }
      }
    }
    setLoading(false);

    // Adiciona interceptor para tratar erros de autenticação
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );

    // Limpa o interceptor quando o componente for desmontado
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  const login = (token: string, userData: User, refreshToken: string) => {
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('user', JSON.stringify(userData));
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    delete axios.defaults.headers.common['Authorization'];
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        login,
        logout,
        isAuthenticated: !!user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
