import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';

const tiers = [
  {
    name: 'Básico',
    id: 'tier-basic',
    href: '#',
    priceMonthly: 'R$299',
    description: 'Perfeito para pequenas empresas que estão começando sua jornada digital.',
    features: [
      'Até 25 automações',
      'Suporte por email',
      'Analytics básico',
      'Tempo de resposta em 24h',
    ],
    featured: false,
  },
  {
    name: 'Empresarial',
    id: 'tier-enterprise',
    href: '#',
    priceMonthly: 'R$999',
    description: 'Suporte dedicado e infraestrutura completa para sua empresa.',
    features: [
      'Automações ilimitadas',
      'Suporte 24/7',
      'Analytics avançado',
      'Gerente de conta dedicado',
      'Marketing automation',
      'Integrações customizadas',
    ],
    featured: true,
  },
];

function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}

const Pricing: React.FC = () => {
  return (
    <section className="relative isolate bg-white dark:bg-[#111111] px-6 py-24 transition-colors duration-300">
      <div aria-hidden="true" className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl">
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-neon-green to-[#111111] opacity-30 dark:opacity-20"
        />
      </div>
      <div className="mx-auto max-w-4xl text-center">
        <h2 className="text-base font-semibold text-neon-green">Preços</h2>
        <p className="mt-2 text-5xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl">
          Escolha o plano ideal para você
        </p>
      </div>
      <p className="mx-auto mt-6 max-w-2xl text-center text-lg text-gray-600 dark:text-gray-400">
        Escolha um plano acessível com os melhores recursos para engajar seu público, criar fidelidade com o cliente e impulsionar suas vendas.
      </p>
      <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
        {tiers.map((tier, tierIdx) => (
          <div
            key={tier.id}
            className={classNames(
              tier.featured ? 'relative bg-[#1A1A1A] dark:bg-gray-900 shadow-2xl' : 'bg-white/60 dark:bg-[#1A1A1A]/60 sm:mx-8 lg:mx-0',
              tier.featured
                ? ''
                : tierIdx === 0
                  ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none'
                  : 'sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl',
              'rounded-3xl p-8 ring-1 ring-gray-900/10 dark:ring-gray-100/10 sm:p-10',
            )}
          >
            <h3
              id={tier.id}
              className={classNames(tier.featured ? 'text-neon-green' : 'text-neon-green', 'text-base font-semibold')}
            >
              {tier.name}
            </h3>
            <p className="mt-4 flex items-baseline gap-x-2">
              <span
                className={classNames(
                  tier.featured ? 'text-white' : 'text-gray-900 dark:text-white',
                  'text-5xl font-semibold tracking-tight',
                )}
              >
                {tier.priceMonthly}
              </span>
              <span className={classNames(tier.featured ? 'text-gray-400' : 'text-gray-500 dark:text-gray-400', 'text-base')}>/mês</span>
            </p>
            <p className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600 dark:text-gray-400', 'mt-6')}>
              {tier.description}
            </p>
            <ul
              className={classNames(
                tier.featured ? 'text-gray-300' : 'text-gray-600 dark:text-gray-400',
                'mt-8 space-y-3 text-sm sm:mt-10',
              )}
            >
              {tier.features.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    aria-hidden="true"
                    className={classNames(tier.featured ? 'text-neon-green' : 'text-neon-green', 'h-6 w-5 flex-none')}
                  />
                  {feature}
                </li>
              ))}
            </ul>
            <a
              href={tier.href}
              aria-describedby={tier.id}
              className={classNames(
                tier.featured
                  ? 'bg-neon-green text-black shadow-sm hover:bg-neon-green/90'
                  : 'text-black dark:text-white ring-1 ring-neon-green hover:bg-neon-green hover:text-black',
                'mt-8 block rounded-full px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10 transition-all duration-300',
              )}
            >
              Comece agora
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Pricing;
