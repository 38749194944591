import React, { useState, useEffect } from 'react';
import ExcelJS from 'exceljs';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';

interface Lead {
  id: string;
  nome: string;
  email: string;
  telefone?: string;
  mensagem?: string;
  criado_em: string;
}

interface NewLead {
  nome: string;
  email: string;
  telefone: string;
  mensagem: string;
}

const Leads: React.FC = () => {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState(true);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [newLead, setNewLead] = useState<NewLead>({
    nome: '',
    email: '',
    telefone: '',
    mensagem: ''
  });
  const [editingLead, setEditingLead] = useState<Lead | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLeads, setFilteredLeads] = useState<Lead[]>([]);
  const { } = useAuth();

  const fetchLeads = async () => {
    try {
      const response = await api.get('/leads');
      setLeads(response.data);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, []);

  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredLeads(leads);
      return;
    }

    const searchTermLower = searchTerm.toLowerCase();
    const filtered = leads.filter(lead => {
      return (
        lead.nome?.toLowerCase().includes(searchTermLower) ||
        lead.email?.toLowerCase().includes(searchTermLower) ||
        lead.telefone?.toLowerCase().includes(searchTermLower) ||
        lead.mensagem?.toLowerCase().includes(searchTermLower)
      );
    });
    setFilteredLeads(filtered);
  }, [searchTerm, leads]);

  const handleDelete = async (leadId: string) => {
    if (!window.confirm('Tem certeza que deseja excluir este lead?')) {
      return;
    }

    try {
      await api.delete(`/leads/${leadId}`);
      setLeads(leads.filter(lead => lead.id !== leadId));
    } catch (err: any) {
      console.error(err);
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewLead(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddLead = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const response = await api.post('/leads', newLead);
      
      setLeads([response.data, ...leads]);
      setIsAddModalOpen(false);
      setNewLead({
        nome: '',
        email: '',
        telefone: '',
        mensagem: ''
      });
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleEdit = (lead: Lead) => {
    setEditingLead(lead);
    setIsEditModalOpen(true);
  };

  const handleEditSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingLead) return;

    try {
      const { id, criado_em, ...updateData } = editingLead;
      const response = await api.put(`/leads/${editingLead.id}`, updateData);
      
      setLeads(leads.map(lead => 
        lead.id === editingLead.id ? { ...response.data } : lead
      ));
      setIsEditModalOpen(false);
      setEditingLead(null);
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditingLead(prev => prev ? { ...prev, [name]: value } : null);
  };

  const parseCSVContent = (content: string): Lead[] => {
    // Detectar o delimitador (vírgula ou ponto e vírgula)
    const firstLine = content.split('\n')[0];
    const delimiter = firstLine.includes(';') ? ';' : ',';
    
    // Dividir o conteúdo em linhas
    const lines = content.split('\n').filter(line => line.trim());
    const headers = lines[0].split(delimiter).map(header => header.trim().toLowerCase());
    
    return lines.slice(1).map(line => {
      const values = line.split(delimiter).map(value => value.trim());
      const lead: any = {};
      
      headers.forEach((header, index) => {
        lead[header] = values[index] || '';
      });
      
      return lead as Lead;
    });
  };

  const parseExcelFile = async (file: File): Promise<Lead[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const buffer = e.target?.result as ArrayBuffer;
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(buffer);
          
          const worksheet = workbook.getWorksheet(1);
          if (!worksheet) {
            throw new Error('Planilha vazia ou inválida');
          }

          const jsonData: any[] = [];
          const headers: string[] = [];

          // Get headers from the first row
          worksheet.getRow(1).eachCell((cell, colNumber) => {
            headers[colNumber - 1] = cell.value?.toString().toLowerCase().trim() || '';
          });

          // Get data from remaining rows
          worksheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) return; // Skip header row

            const rowData: any = {};
            row.eachCell((cell, colNumber) => {
              const header = headers[colNumber - 1];
              if (header) {
                rowData[header] = cell.value?.toString().trim() || '';
              }
            });
            jsonData.push(rowData);
          });
          
          resolve(jsonData as Lead[]);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const validateLeads = (leads: Lead[]): { valid: boolean; errors: string[] } => {
    const errors: string[] = [];
    
    if (leads.length === 0) {
      errors.push('O arquivo está vazio');
      return { valid: false, errors };
    }

    leads.forEach((lead, index) => {
      if (!lead.nome) {
        errors.push(`Linha ${index + 1}: Nome é obrigatório`);
      }
      if (!lead.email) {
        errors.push(`Linha ${index + 1}: Email é obrigatório`);
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(lead.email)) {
        errors.push(`Linha ${index + 1}: Email inválido`);
      }
    });

    return { valid: errors.length === 0, errors };
  };

  const handleBulkImport = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!csvFile) {
      console.error('Por favor, selecione um arquivo');
      return;
    }

    try {
      setLoading(true);
      let leads: Lead[] = [];
      
      const fileExtension = csvFile.name.split('.').pop()?.toLowerCase();
      
      if (fileExtension === 'csv') {
        const text = await csvFile.text();
        leads = parseCSVContent(text);
      } else if (['xls', 'xlsx'].includes(fileExtension || '')) {
        leads = await parseExcelFile(csvFile);
      } else {
        console.error('Formato de arquivo não suportado. Use CSV, XLS ou XLSX');
        return;
      }

      const validation = validateLeads(leads);
      if (!validation.valid) {
        validation.errors.forEach(error => console.error(error));
        return;
      }

      const response = await api.post('/leads/bulk', leads);
      
      setLeads(prevLeads => [...response.data, ...prevLeads]);
      setIsBulkModalOpen(false);
      setCsvFile(null);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setCsvFile(e.target.files[0]);
    }
  };

  const downloadTemplate = () => {
    // Criar o conteúdo do CSV
    const headers = ['nome', 'email', 'telefone', 'mensagem'];
    const exampleRow = ['João Silva', 'joao@email.com', '(11) 98765-4321', 'Interessado em nossos serviços'];
    const csvContent = [
      headers.join(','),
      exampleRow.join(',')
    ].join('\n');

    // Criar o blob e link para download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'template_leads.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const highlightText = (text: string = '') => {
    if (!searchTerm.trim()) return text;
    
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return (
      <>
        {parts.map((part, index) => 
          part.toLowerCase() === searchTerm.toLowerCase() ? (
            <span key={index} className="bg-yellow-500 text-black px-1 rounded">
              {part}
            </span>
          ) : (
            part
          )
        )}
      </>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center space-y-4">
          <div className="w-12 h-12 border-4 border-neon-green border-t-transparent rounded-full animate-spin"></div>
          <div className="text-gray-300 font-medium">Carregando leads...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      <div className="bg-gradient-to-r from-[#1a1a1a] to-[#2a2a2a] rounded-2xl p-8 shadow-xl">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
            Gerenciamento de Leads
          </h1>
          <div className="flex flex-wrap gap-3">
            <button
              onClick={() => setIsAddModalOpen(true)}
              className="inline-flex items-center px-4 py-2.5 bg-neon-green text-black rounded-xl hover:bg-neon-green/90 transition-all duration-300 transform hover:scale-105 font-medium shadow-lg shadow-neon-green/20"
            >
              <span className="mr-2">+</span>
              Adicionar Lead
            </button>
            <button
              onClick={() => setIsBulkModalOpen(true)}
              className="inline-flex items-center px-4 py-2.5 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-all duration-300 transform hover:scale-105 font-medium shadow-lg shadow-blue-600/20"
            >
              <span className="mr-2">📥</span>
              Importar Leads
            </button>
            <button
              onClick={downloadTemplate}
              className="inline-flex items-center px-4 py-2.5 bg-purple-600 text-white rounded-xl hover:bg-purple-700 transition-all duration-300 transform hover:scale-105 font-medium shadow-lg shadow-purple-600/20"
            >
              <span className="mr-2">📄</span>
              Template CSV
            </button>
          </div>
        </div>

        <div className="mt-8">
          <div className="relative">
            <input
              type="text"
              placeholder="Buscar leads por nome, email, telefone..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-6 py-3 bg-[#252525] text-white rounded-xl focus:ring-2 focus:ring-neon-green focus:outline-none pl-12 pr-12 transition-all duration-300 border border-gray-700 hover:border-gray-600"
            />
            <div className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            {searchTerm && (
              <button
                onClick={() => setSearchTerm('')}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white transition-colors duration-200"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="bg-[#1a1a1a] rounded-2xl shadow-xl border border-gray-800 overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-800">
            <thead>
              <tr className="bg-[#252525]">
                <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Nome
                </th>
                <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Email
                </th>
                <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider hidden sm:table-cell">
                  Telefone
                </th>
                <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider hidden md:table-cell">
                  Mensagem
                </th>
                <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Data
                </th>
                <th scope="col" className="px-6 py-4 text-right text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-800">
              {filteredLeads.map((lead, index) => (
                <tr 
                  key={lead.id || index}
                  className="hover:bg-[#252525] transition-colors duration-200"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-white">{highlightText(lead.nome)}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-300">{highlightText(lead.email)}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap hidden sm:table-cell">
                    <div className="text-sm text-gray-300">{highlightText(lead.telefone)}</div>
                  </td>
                  <td className="px-6 py-4 hidden md:table-cell">
                    <div className="text-sm text-gray-300 truncate max-w-xs">
                      {highlightText(lead.mensagem)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-300">{formatDate(lead.criado_em)}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-3">
                    <button
                      onClick={() => handleEdit(lead)}
                      className="text-blue-400 hover:text-blue-300 transition-colors duration-200"
                    >
                      <span className="sr-only">Editar</span>
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                    </button>
                    <button
                      onClick={() => handleDelete(lead.id)}
                      className="text-red-400 hover:text-red-300 transition-colors duration-200"
                    >
                      <span className="sr-only">Excluir</span>
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal de Adição Rápida */}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-[#1a1a1a] rounded-2xl p-8 w-full max-w-md border border-gray-800 shadow-2xl transform transition-all">
            <h2 className="text-2xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
              Adicionar Novo Lead
            </h2>
            <form onSubmit={handleAddLead} className="space-y-6">
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Nome*</label>
                <input
                  type="text"
                  name="nome"
                  value={newLead.nome}
                  onChange={handleInputChange}
                  required
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                  placeholder="Nome do lead"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Email*</label>
                <input
                  type="email"
                  name="email"
                  value={newLead.email}
                  onChange={handleInputChange}
                  required
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                  placeholder="email@exemplo.com"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Telefone</label>
                <input
                  type="tel"
                  name="telefone"
                  value={newLead.telefone}
                  onChange={handleInputChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                  placeholder="(00) 00000-0000"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Mensagem</label>
                <textarea
                  name="mensagem"
                  value={newLead.mensagem}
                  onChange={handleInputChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200 h-24 resize-none"
                  placeholder="Mensagem do lead..."
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setIsAddModalOpen(false);
                    setNewLead({
                      nome: '',
                      email: '',
                      telefone: '',
                      mensagem: ''
                    });
                  }}
                  className="px-4 py-2.5 text-gray-300 hover:text-white transition-colors duration-200"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-4 py-2.5 bg-neon-green text-black rounded-xl hover:bg-neon-green/90 transition-all duration-300 transform hover:scale-105 font-medium shadow-lg shadow-neon-green/20"
                >
                  Adicionar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Modal de Edição */}
      {isEditModalOpen && editingLead && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-[#1a1a1a] rounded-2xl p-8 w-full max-w-md border border-gray-800 shadow-2xl transform transition-all">
            <h2 className="text-2xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
              Editar Lead
            </h2>
            <form onSubmit={handleEditSubmit} className="space-y-6">
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Nome*</label>
                <input
                  type="text"
                  name="nome"
                  value={editingLead.nome}
                  onChange={handleEditInputChange}
                  required
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Email*</label>
                <input
                  type="email"
                  name="email"
                  value={editingLead.email}
                  onChange={handleEditInputChange}
                  required
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Telefone</label>
                <input
                  type="tel"
                  name="telefone"
                  value={editingLead.telefone || ''}
                  onChange={handleEditInputChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Mensagem</label>
                <textarea
                  name="mensagem"
                  value={editingLead.mensagem || ''}
                  onChange={handleEditInputChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200 h-24 resize-none"
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setIsEditModalOpen(false);
                    setEditingLead(null);
                  }}
                  className="px-4 py-2.5 text-gray-300 hover:text-white transition-colors duration-200"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-4 py-2.5 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-all duration-300 transform hover:scale-105 font-medium shadow-lg shadow-blue-600/20"
                >
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Modal de Importação em Massa */}
      {isBulkModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-[#1a1a1a] rounded-2xl p-8 w-full max-w-md border border-gray-800 shadow-2xl transform transition-all">
            <h2 className="text-2xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
              Importar Leads via CSV
            </h2>
            <form onSubmit={handleBulkImport} className="space-y-6">
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Arquivo (CSV, XLS, XLSX)</label>
                <input
                  type="file"
                  accept=".csv,.xls,.xlsx"
                  onChange={handleFileChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                />
                <p className="text-sm text-gray-400 mt-2">
                  O arquivo deve conter as colunas: nome, email, telefone (opcional), mensagem (opcional)
                  <br />
                  Para arquivos CSV, são aceitos delimitadores vírgula (,) ou ponto e vírgula (;)
                </p>
                <div className="mt-2">
                  <button
                    type="button"
                    onClick={downloadTemplate}
                    className="text-purple-400 hover:text-purple-300 text-sm flex items-center"
                  >
                    <span className="mr-1">📥</span>
                    Baixar template CSV
                  </button>
                </div>
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setIsBulkModalOpen(false);
                    setCsvFile(null);
                  }}
                  className="px-4 py-2.5 text-gray-300 hover:text-white transition-colors duration-200"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-4 py-2.5 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-all duration-300 transform hover:scale-105 font-medium shadow-lg shadow-blue-600/20"
                >
                  Importar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Leads;
