import React, { useEffect, useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import axios from 'axios';
import { FiPlus, FiEdit2, FiTrash2, FiX } from 'react-icons/fi';
import Modal from '../../components/Modal';

interface Service {
  id: string;
  nome: string;
  descricao?: string;
  icone?: string;
}

const Services: React.FC = () => {
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentService, setCurrentService] = useState<Service | null>(null);
  const [notification, setNotification] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const { isDarkMode } = useTheme();

  // Form states
  const [formData, setFormData] = useState({
    nome: '',
    descricao: '',
    icone: ''
  });

  const fetchServices = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/services`);
      if (Array.isArray(response.data)) {
        setServices(response.data);
      } else if (response.data && Array.isArray(response.data.services)) {
        setServices(response.data.services);
      } else {
        console.error('Formato de resposta inválido:', response.data);
        setError('Erro ao carregar a lista de serviços: formato inválido');
        setServices([]);
      }
    } catch (err) {
      console.error('Erro ao carregar serviços:', err);
      setError('Erro ao carregar a lista de serviços');
      setServices([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const showNotification = (type: 'success' | 'error', message: string) => {
    setNotification({ type, message });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (currentService) {
        // Atualização
        await axios.put(
          `${process.env.REACT_APP_API_URL}/services/${currentService.id}`,
          formData
        );
        showNotification('success', 'Serviço atualizado com sucesso!');
      } else {
        // Criação
        await axios.post(`${process.env.REACT_APP_API_URL}/services`, formData);
        showNotification('success', 'Serviço criado com sucesso!');
      }
      setIsModalOpen(false);
      setCurrentService(null);
      setFormData({ nome: '', descricao: '', icone: '' });
      fetchServices();
    } catch (err) {
      console.error('Erro ao salvar serviço:', err);
      showNotification('error', 'Erro ao salvar serviço');
    }
  };

  const handleEdit = (service: Service) => {
    setCurrentService(service);
    setFormData({
      nome: service.nome,
      descricao: service.descricao || '',
      icone: service.icone || ''
    });
    setIsModalOpen(true);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Tem certeza que deseja excluir este serviço?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/services/${id}`);
        showNotification('success', 'Serviço excluído com sucesso!');
        fetchServices();
      } catch (err) {
        console.error('Erro ao excluir serviço:', err);
        showNotification('error', 'Erro ao excluir serviço');
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[60vh]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-neon-green"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      {notification && (
        <div
          className={`fixed top-4 right-4 px-6 py-3 rounded-lg shadow-lg ${
            notification.type === 'success'
              ? 'bg-green-500 text-white'
              : 'bg-red-500 text-white'
          }`}
        >
          {notification.message}
        </div>
      )}

      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className={`text-3xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Serviços
          </h1>
          <p className={`mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            Gerencie os serviços disponíveis
          </p>
        </div>
        <button
          onClick={() => {
            setCurrentService(null);
            setFormData({ nome: '', descricao: '', icone: '' });
            setIsModalOpen(true);
          }}
          className={`flex items-center px-4 py-2 rounded-lg transition-colors duration-200 ${
            isDarkMode
              ? 'bg-green-500/20 text-green-400 hover:bg-green-500/30'
              : 'bg-green-500 text-white hover:bg-green-600'
          }`}
        >
          <FiPlus className="w-5 h-5 mr-2" />
          Novo Serviço
        </button>
      </div>

      {error && (
        <div className="mb-6 flex items-center justify-between bg-red-500/10 border border-red-500 text-red-500 px-4 py-3 rounded-lg">
          <span>{error}</span>
          <button
            onClick={() => setError(null)}
            className="text-red-500 hover:text-red-600 transition-colors duration-200"
          >
            <FiX className="w-5 h-5" />
          </button>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {Array.isArray(services) && services.length > 0 ? (
          services.map((service) => (
            <div
              key={service.id}
              className={`${
                isDarkMode
                  ? 'bg-[#1a1a1a] border-gray-800 hover:bg-[#252525]'
                  : 'bg-white border-gray-200 hover:bg-gray-50'
              } border rounded-xl p-6 transition-all duration-200`}
            >
              <div className="flex items-center mb-4">
                {service.icone && (
                  <div className="w-12 h-12 rounded-lg mr-4 flex items-center justify-center bg-gradient-to-br from-purple-500 to-pink-500">
                    <span className="text-2xl text-white">{service.icone}</span>
                  </div>
                )}
                <div className="flex-1">
                  <h3 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {service.nome}
                  </h3>
                  {service.descricao && (
                    <p className={`text-sm mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      {service.descricao}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => handleEdit(service)}
                  className={`p-2 rounded-lg transition-colors duration-200 ${
                    isDarkMode
                      ? 'text-gray-400 hover:text-blue-500 hover:bg-blue-500/10'
                      : 'text-gray-500 hover:text-blue-500 hover:bg-blue-500/10'
                  }`}
                >
                  <FiEdit2 className="w-5 h-5" />
                </button>
                <button
                  onClick={() => handleDelete(service.id)}
                  className={`p-2 rounded-lg transition-colors duration-200 ${
                    isDarkMode
                      ? 'text-gray-400 hover:text-red-500 hover:bg-red-500/10'
                      : 'text-gray-500 hover:text-red-500 hover:bg-red-500/10'
                  }`}
                >
                  <FiTrash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className={`col-span-3 text-center py-8 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            Nenhum serviço encontrado
          </div>
        )}
      </div>

      {/* Modal de Formulário */}
      <Modal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
        title={currentService ? 'Editar Serviço' : 'Novo Serviço'}
      >
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="nome"
                className={`block text-sm font-medium mb-1 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}
              >
                Nome
              </label>
              <input
                type="text"
                id="nome"
                value={formData.nome}
                onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? 'bg-[#252525] border-gray-700 text-white'
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:outline-none focus:ring-2 focus:ring-neon-green`}
                required
              />
            </div>

            <div>
              <label
                htmlFor="descricao"
                className={`block text-sm font-medium mb-1 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}
              >
                Descrição
              </label>
              <textarea
                id="descricao"
                value={formData.descricao}
                onChange={(e) => setFormData({ ...formData, descricao: e.target.value })}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? 'bg-[#252525] border-gray-700 text-white'
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:outline-none focus:ring-2 focus:ring-neon-green`}
                rows={3}
              />
            </div>

            <div>
              <label
                htmlFor="icone"
                className={`block text-sm font-medium mb-1 ${
                  isDarkMode ? 'text-gray-300' : 'text-gray-700'
                }`}
              >
                Ícone
              </label>
              <input
                type="text"
                id="icone"
                value={formData.icone}
                onChange={(e) => setFormData({ ...formData, icone: e.target.value })}
                className={`w-full px-3 py-2 rounded-lg border ${
                  isDarkMode
                    ? 'bg-[#252525] border-gray-700 text-white'
                    : 'bg-white border-gray-300 text-gray-900'
                } focus:outline-none focus:ring-2 focus:ring-neon-green`}
                placeholder="Emoji ou código do ícone"
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-green-500/20 text-green-400 hover:bg-green-500/30'
                  : 'bg-green-500 text-white hover:bg-green-600'
              }`}
            >
              {currentService ? 'Atualizar' : 'Criar'}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Services;
