import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { FiEdit2, FiTrash2, FiUser, FiMail, FiBriefcase, FiCalendar } from 'react-icons/fi';

interface User {
  id: string;
  nome: string;
  email: string;
  nivel_acesso: string;
  telefone?: string;
  empresa?: string;
  endereco?: string;
  created_at: string;
}

interface EditUserForm {
  nome: string;
  email: string;
  nivel_acesso: string;
  telefone: string;
  empresa: string;
  endereco: string;
}

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<EditUserForm | null>(null);
  const { user: currentUser } = useAuth();

  const fetchUsers = async () => {
    try {
      const response = await api.get('/users');
      setUsers(response.data);
      setError('');
    } catch (err: any) {
      setError(err.response?.data?.error || 'Erro ao carregar usuários');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleDelete = async (userId: string) => {
    if (!window.confirm('Tem certeza que deseja excluir este usuário?')) {
      return;
    }

    try {
      await api.delete(`/users/${userId}`);
      setUsers(users.filter(u => u.id !== userId));
      setError('');
    } catch (err: any) {
      setError(err.response?.data?.error || 'Erro ao excluir usuário');
    }
  };

  const handleEdit = (user: User) => {
    setEditingUser({
      nome: user.nome,
      email: user.email,
      nivel_acesso: user.nivel_acesso,
      telefone: user.telefone || '',
      empresa: user.empresa || '',
      endereco: user.endereco || '',
    });
    setIsEditModalOpen(true);
  };

  const handleEditSubmit = async (userId: string) => {
    try {
      const response = await api.put(`/users/${userId}`, editingUser);
      setUsers(users.map(u => u.id === userId ? { ...u, ...response.data } : u));
      setIsEditModalOpen(false);
      setEditingUser(null);
      setError('');
    } catch (err: any) {
      setError(err.response?.data?.error || 'Erro ao atualizar usuário');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setEditingUser(prev => prev ? { ...prev, [name]: value } : null);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center space-y-4">
          <div className="w-12 h-12 border-4 border-neon-green border-t-transparent rounded-full animate-spin"></div>
          <div className="text-gray-300 font-medium">Carregando usuários...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      <div className="bg-gradient-to-r from-[#1a1a1a] to-[#2a2a2a] rounded-2xl p-8 shadow-xl">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <div>
            <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
              Gerenciamento de Usuários
            </h1>
            <p className="mt-2 text-gray-400">
              {users.length} {users.length === 1 ? 'usuário cadastrado' : 'usuários cadastrados'}
            </p>
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-red-500/10 border border-red-500 text-red-500 p-4 rounded-xl flex items-center">
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          {error}
        </div>
      )}

      <div className="bg-[#1a1a1a] rounded-2xl shadow-xl border border-gray-800 overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-800">
            <thead>
              <tr className="bg-[#252525]">
                <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  <div className="flex items-center space-x-2">
                    <FiUser className="w-4 h-4" />
                    <span>Usuário</span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider hidden sm:table-cell">
                  <div className="flex items-center space-x-2">
                    <FiMail className="w-4 h-4" />
                    <span>Email</span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Nível
                </th>
                <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider hidden md:table-cell">
                  <div className="flex items-center space-x-2">
                    <FiBriefcase className="w-4 h-4" />
                    <span>Empresa</span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider hidden lg:table-cell">
                  <div className="flex items-center space-x-2">
                    <FiCalendar className="w-4 h-4" />
                    <span>Criado em</span>
                  </div>
                </th>
                <th scope="col" className="px-6 py-4 text-right text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-800">
              {users.map((user) => (
                <tr key={user.id} className="hover:bg-[#252525] transition-colors duration-200">
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        <div className="h-10 w-10 rounded-full bg-gradient-to-br from-neon-green/20 to-blue-500/20 flex items-center justify-center">
                          <span className="text-white text-lg font-medium">
                            {user.nome.charAt(0).toUpperCase()}
                          </span>
                        </div>
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-white">{user.nome}</div>
                        <div className="text-sm text-gray-400 sm:hidden">{user.email}</div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap hidden sm:table-cell">
                    <div className="text-sm text-gray-300">{user.email}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${
                      user.nivel_acesso === '1' 
                        ? 'bg-neon-green/20 text-neon-green border border-neon-green/30' 
                        : 'bg-gray-600/20 text-gray-300 border border-gray-600/30'
                    }`}>
                      {user.nivel_acesso === '1' ? 'Admin' : 'Usuário'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap hidden md:table-cell">
                    <div className="text-sm text-gray-300">{user.empresa || '-'}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300 hidden lg:table-cell">
                    {formatDate(user.created_at)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex justify-end space-x-3">
                      <button
                        onClick={() => handleEdit(user)}
                        className="text-blue-400 hover:text-blue-300 transition-colors duration-200"
                      >
                        <span className="sr-only">Editar</span>
                        <FiEdit2 className="w-5 h-5" />
                      </button>
                      {user.id !== currentUser?.id && (
                        <button
                          onClick={() => handleDelete(user.id)}
                          className="text-red-400 hover:text-red-300 transition-colors duration-200"
                        >
                          <span className="sr-only">Excluir</span>
                          <FiTrash2 className="w-5 h-5" />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal de Edição */}
      {isEditModalOpen && editingUser && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-[#1a1a1a] rounded-2xl p-8 w-full max-w-md border border-gray-800 shadow-2xl transform transition-all">
            <h2 className="text-2xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
              Editar Usuário
            </h2>
            <form onSubmit={(e) => {
              e.preventDefault();
              const userId = users.find(u => u.nome === editingUser.nome)?.id;
              if (userId) handleEditSubmit(userId);
            }} className="space-y-6">
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Nome</label>
                <input
                  type="text"
                  name="nome"
                  value={editingUser.nome}
                  onChange={handleInputChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Email</label>
                <input
                  type="email"
                  name="email"
                  value={editingUser.email}
                  onChange={handleInputChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Nível de Acesso</label>
                <select
                  name="nivel_acesso"
                  value={editingUser.nivel_acesso}
                  onChange={handleInputChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                >
                  <option value="1">Admin</option>
                  <option value="2">Usuário</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Telefone</label>
                <input
                  type="text"
                  name="telefone"
                  value={editingUser.telefone}
                  onChange={handleInputChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Empresa</label>
                <input
                  type="text"
                  name="empresa"
                  value={editingUser.empresa}
                  onChange={handleInputChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2 font-medium">Endereço</label>
                <input
                  type="text"
                  name="endereco"
                  value={editingUser.endereco}
                  onChange={handleInputChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setIsEditModalOpen(false);
                    setEditingUser(null);
                  }}
                  className="px-4 py-2.5 text-gray-300 hover:text-white transition-colors duration-200"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="px-4 py-2.5 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-all duration-300 transform hover:scale-105 font-medium shadow-lg shadow-blue-600/20"
                >
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
