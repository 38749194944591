import axios from 'axios';

const evoApi = axios.create({
  baseURL: 'https://evo.moxxy.com.br',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'apikey': '429683C4C977415CAAFCCE10F7D57E111997',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization, apikey'
  }
});

// Interceptor para adicionar token de autorização (se necessário)
evoApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('evoToken'); 
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor para tratar erros de resposta
evoApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Erro na chamada da API:', error);
    if (error.response) {
      console.error('Detalhes do erro:', error.response.data);
    }
    return Promise.reject(error);
  }
);

export default evoApi;