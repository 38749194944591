import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';

const Register: React.FC = () => {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    senha: '',
    confirmarSenha: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  useTheme();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    // Validação das senhas
    if (formData.senha !== formData.confirmarSenha) {
      setError('As senhas não coincidem');
      return;
    }

    if (formData.senha.length < 6) {
      setError('A senha deve ter pelo menos 6 caracteres');
      return;
    }

    setLoading(true);

    try {
      // Remove confirmarSenha antes de enviar para a API
      const { confirmarSenha, ...registrationData } = formData;
      await api.post('/auth/register', registrationData);

      // Login automático após registro
      const loginResponse = await api.post('/auth/login', {
        email: formData.email,
        senha: formData.senha
      });

      const { token, refreshToken, user } = loginResponse.data;
      login(token, user, refreshToken);
      navigate('/dashboard');
    } catch (err: any) {
      setError(err.response?.data?.error || 'Erro ao registrar. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen bg-gray-50 dark:bg-[#111111] flex items-center justify-center px-4 transition-colors duration-300 overflow-hidden">
      <div className="relative isolate">
        {/* Gradient Background */}
        <div aria-hidden="true" className="absolute inset-0 -z-10 transform-gpu overflow-hidden blur-[100px]">
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative -left-[calc(50%+5rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-neon-green via-transparent to-transparent opacity-30 sm:w-[72.1875rem]"
          />
        </div>

        {/* Second gradient for better distribution */}
        <div aria-hidden="true" className="absolute inset-0 -z-10 transform-gpu overflow-hidden blur-[100px]">
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%+5rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-bl from-neon-green via-transparent to-transparent opacity-30 sm:w-[72.1875rem]"
          />
        </div>

        <div className="mx-auto max-w-xl w-full px-8">
          {/* Logo */}
          <div className="text-center">
            <div className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
              M<span className="text-neon-green">oxxy</span>
            </div>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
              Crie sua conta gratuitamente
            </h2>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              Ou{' '}
              <button
                type="button"
                onClick={() => navigate('/login')}
                className="font-medium text-neon-green hover:text-neon-green/90"
              >
                faça login se já tiver uma conta
              </button>
            </p>
          </div>

          <form className="mt-8 space-y-6 bg-white dark:bg-[#1A1A1A] p-8 rounded-2xl shadow-sm border border-gray-100 dark:border-[#333333] w-full min-w-[400px]" onSubmit={handleSubmit}>
            {error && (
              <div className="bg-red-500/10 border border-red-500/20 text-red-500 p-3 rounded-lg text-sm text-center">
                {error}
              </div>
            )}

            <div className="space-y-5">
              <div>
                <label htmlFor="nome" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Nome completo
                </label>
                <input
                  id="nome"
                  name="nome"
                  type="text"
                  required
                  className="block w-full rounded-lg px-4 py-3 bg-gray-50 dark:bg-[#111111] border border-gray-300 dark:border-[#333333] text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-neon-green focus:border-transparent sm:text-sm transition-colors duration-200"
                  placeholder="Digite seu nome"
                  value={formData.nome}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="block w-full rounded-lg px-4 py-3 bg-gray-50 dark:bg-[#111111] border border-gray-300 dark:border-[#333333] text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-neon-green focus:border-transparent sm:text-sm transition-colors duration-200"
                  placeholder="Digite seu email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="senha" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Senha
                </label>
                <input
                  id="senha"
                  name="senha"
                  type="password"
                  required
                  className="block w-full rounded-lg px-4 py-3 bg-gray-50 dark:bg-[#111111] border border-gray-300 dark:border-[#333333] text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-neon-green focus:border-transparent sm:text-sm transition-colors duration-200"
                  placeholder="Digite sua senha"
                  value={formData.senha}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="confirmarSenha" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Confirmar senha
                </label>
                <input
                  id="confirmarSenha"
                  name="confirmarSenha"
                  type="password"
                  required
                  className="block w-full rounded-lg px-4 py-3 bg-gray-50 dark:bg-[#111111] border border-gray-300 dark:border-[#333333] text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-neon-green focus:border-transparent sm:text-sm transition-colors duration-200"
                  placeholder="Confirme sua senha"
                  value={formData.confirmarSenha}
                  onChange={handleChange}
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="flex w-full justify-center rounded-full bg-neon-green px-3.5 py-3 text-sm font-semibold text-black shadow-sm hover:bg-neon-green/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neon-green disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300"
            >
              {loading ? 'Criando conta...' : 'Criar conta gratuitamente'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
