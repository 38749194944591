import React, { useState } from 'react';
import api from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { FiUser, FiMail, FiPhone, FiBriefcase, FiMapPin, FiLock, FiCheck, FiX } from 'react-icons/fi';
import { useTheme } from '../../contexts/ThemeContext';

const Profile: React.FC = () => {
  const { user, login } = useAuth();
  const { isDarkMode } = useTheme();
  const [formData, setFormData] = useState({
    nome: user?.nome || '',
    email: user?.email || '',
    telefone: user?.telefone || '',
    empresa: user?.empresa || '',
    endereco: user?.endereco || '',
    senha: '',
    novaSenha: ''
  });
  const [message, setMessage] = useState({ type: '', text: '' });
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.put('/users/profile', formData);
      if (response.data) {
        // Get token from localStorage since it's still valid
        const token = localStorage.getItem('token') || '';
        const refreshToken = localStorage.getItem('refreshToken') || '';
        login(token, response.data, refreshToken);
        setMessage({ type: 'success', text: 'Perfil atualizado com sucesso!' });
        if (formData.senha) {
          setFormData(prev => ({
            ...prev,
            senha: '',
            novaSenha: ''
          }));
        }
      }
    } catch (error) {
      setMessage({ type: 'error', text: 'Erro ao atualizar perfil. Tente novamente.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className={`${isDarkMode ? 'bg-gradient-to-r from-[#1a1a1a] to-[#2a2a2a]' : 'bg-white'} rounded-2xl p-8 shadow-xl mb-8 ${isDarkMode ? 'border-gray-800' : 'border-gray-200'} border`}>
        <h1 className={`text-3xl font-bold ${isDarkMode ? 'bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent' : 'text-gray-900'}`}>
          Meu Perfil
        </h1>
        <p className={`mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          Gerencie suas informações pessoais e preferências
        </p>
      </div>

      {message.text && (
        <div
          className={`mb-8 rounded-xl p-4 flex items-center space-x-3 ${
            message.type === 'success'
              ? 'bg-green-500/10 border border-green-500 text-green-500'
              : 'bg-red-500/10 border border-red-500 text-red-500'
          }`}
        >
          {message.type === 'success' ? (
            <FiCheck className="w-5 h-5 flex-shrink-0" />
          ) : (
            <FiX className="w-5 h-5 flex-shrink-0" />
          )}
          <span>{message.text}</span>
        </div>
      )}

      <div className={`${isDarkMode ? 'bg-[#1a1a1a] border-gray-800' : 'bg-white border-gray-200'} rounded-2xl shadow-xl border`}>
        <form onSubmit={handleSubmit} className="p-8 space-y-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <label htmlFor="nome" className="block text-gray-300 text-sm font-medium mb-2 flex items-center space-x-2">
                <FiUser className="w-4 h-4" />
                <span>Nome</span>
              </label>
              <input
                type="text"
                id="nome"
                name="nome"
                value={formData.nome}
                onChange={handleChange}
                className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                required
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-gray-300 text-sm font-medium mb-2 flex items-center space-x-2">
                <FiMail className="w-4 h-4" />
                <span>Email</span>
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                className="w-full bg-[#252525] text-white rounded-xl p-3 border border-gray-700 opacity-75 cursor-not-allowed"
                disabled
              />
            </div>

            <div>
              <label htmlFor="telefone" className="block text-gray-300 text-sm font-medium mb-2 flex items-center space-x-2">
                <FiPhone className="w-4 h-4" />
                <span>Telefone</span>
              </label>
              <input
                type="tel"
                id="telefone"
                name="telefone"
                value={formData.telefone}
                onChange={handleChange}
                className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                placeholder="(00) 00000-0000"
              />
            </div>

            <div>
              <label htmlFor="empresa" className="block text-gray-300 text-sm font-medium mb-2 flex items-center space-x-2">
                <FiBriefcase className="w-4 h-4" />
                <span>Empresa</span>
              </label>
              <input
                type="text"
                id="empresa"
                name="empresa"
                value={formData.empresa}
                onChange={handleChange}
                className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                placeholder="Nome da sua empresa"
              />
            </div>

            <div className="md:col-span-2">
              <label htmlFor="endereco" className="block text-gray-300 text-sm font-medium mb-2 flex items-center space-x-2">
                <FiMapPin className="w-4 h-4" />
                <span>Endereço</span>
              </label>
              <textarea
                id="endereco"
                name="endereco"
                value={formData.endereco}
                onChange={handleChange}
                rows={3}
                className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200 resize-none"
                placeholder="Seu endereço completo"
              />
            </div>
          </div>

          <div className="border-t border-gray-800 pt-8">
            <div className="flex items-center space-x-3 mb-6">
              <FiLock className="w-5 h-5 text-gray-400" />
              <h2 className="text-xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                Alterar Senha
              </h2>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <label htmlFor="senha" className="block text-gray-300 text-sm font-medium mb-2">
                  Senha Atual
                </label>
                <input
                  type="password"
                  id="senha"
                  name="senha"
                  value={formData.senha}
                  onChange={handleChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                  placeholder="Digite sua senha atual"
                />
              </div>

              <div>
                <label htmlFor="novaSenha" className="block text-gray-300 text-sm font-medium mb-2">
                  Nova Senha
                </label>
                <input
                  type="password"
                  id="novaSenha"
                  name="novaSenha"
                  value={formData.novaSenha}
                  onChange={handleChange}
                  className="w-full bg-[#252525] text-white rounded-xl p-3 focus:ring-2 focus:ring-neon-green focus:outline-none border border-gray-700 hover:border-gray-600 transition-all duration-200"
                  placeholder="Digite sua nova senha"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end pt-6">
            <button
              type="submit"
              disabled={loading}
              className="inline-flex items-center px-6 py-3 bg-neon-green text-black rounded-xl hover:bg-neon-green/90 transition-all duration-300 transform hover:scale-105 font-medium shadow-lg shadow-neon-green/20 disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none"
            >
              {loading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span>Salvando...</span>
                </>
              ) : (
                <>
                  <FiCheck className="w-5 h-5 mr-2" />
                  <span>Salvar Alterações</span>
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
