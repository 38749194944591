import React from 'react';
import { useTheme } from '../contexts/ThemeContext';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'danger';
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  size = 'md',
  fullWidth = false,
  children,
  className = '',
  disabled,
  ...props
}) => {
  const { isDarkMode } = useTheme();

  const baseClasses = 'font-medium rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2';
  
  const sizeClasses = {
    sm: 'px-3 py-1.5 text-sm',
    md: 'px-4 py-2 text-base',
    lg: 'px-6 py-3 text-lg',
  };

  const variantClasses = {
    primary: `${
      isDarkMode
        ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
        : 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
    }`,
    secondary: `${
      isDarkMode
        ? 'bg-gray-700 hover:bg-gray-600 focus:ring-gray-500 text-white'
        : 'bg-gray-200 hover:bg-gray-300 focus:ring-gray-400 text-gray-700'
    }`,
    danger: `${
      isDarkMode
        ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500 text-white'
        : 'bg-red-600 hover:bg-red-700 focus:ring-red-500 text-white'
    }`,
  };

  const disabledClasses = disabled
    ? 'opacity-50 cursor-not-allowed'
    : 'cursor-pointer';

  const widthClasses = fullWidth ? 'w-full' : '';

  return (
    <button
      className={`${baseClasses} ${sizeClasses[size]} ${variantClasses[variant]} ${widthClasses} ${disabledClasses} ${className}`}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
