import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

const Hero: React.FC = () => {
  return (
    <section className="relative isolate pt-32 pb-20 bg-white dark:bg-[#111111] transition-colors duration-300">
      <div aria-hidden="true" className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl">
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-neon-green to-[#111111] opacity-30 dark:opacity-20"
        />
      </div>
      <div className="container mx-auto px-4">
        <div className="max-w-3xl">
          <h1 className="text-5xl md:text-6xl font-bold mb-4 text-gray-900 dark:text-white">
            Transformamos suas <span className="text-neon-green">ideias</span> em soluções reais
          </h1>
          <p className="text-gray-600 dark:text-gray-400 text-xl mb-8">
            Automatizamos processos, criamos estratégias de marketing e design que fazem a diferença no seu negócio.
          </p>
          <div className="flex gap-4">
            <button className="bg-neon-green hover:bg-neon-green/90 text-black px-8 py-3 rounded-full font-medium transition-all duration-300 transform hover:scale-105 flex items-center gap-2 shadow-sm">
              Comece Agora <ArrowRightIcon className="h-5 w-5" />
            </button>
            <button className="border border-neon-green text-neon-green px-8 py-3 rounded-full font-medium hover:bg-neon-green/10 transition-all duration-300">
              Saiba mais
            </button>
          </div>
        </div>

        {/* Stats */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-20 max-w-3xl">
          <div className="bg-white dark:bg-[#1A1A1A] p-8 rounded-2xl border border-gray-100 dark:border-[#333333] hover:border-neon-green/50 transition-all duration-300 shadow-sm">
            <h3 className="text-neon-green text-4xl font-bold flex items-center gap-2">
              <span>+150%</span>
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mt-2">Aumento em produtividade</p>
          </div>
          <div className="bg-white dark:bg-[#1A1A1A] p-8 rounded-2xl border border-gray-100 dark:border-[#333333] hover:border-neon-green/50 transition-all duration-300 shadow-sm">
            <h3 className="text-neon-green text-4xl font-bold flex items-center gap-2">
              <span>+1000</span>
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mt-2">Clientes satisfeitos</p>
          </div>
          <div className="bg-white dark:bg-[#1A1A1A] p-8 rounded-2xl border border-gray-100 dark:border-[#333333] hover:border-neon-green/50 transition-all duration-300 shadow-sm">
            <h3 className="text-neon-green text-4xl font-bold flex items-center gap-2">
              <span>+200%</span>
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mt-2">Crescimento em vendas</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
