import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { FiUsers, FiTarget, FiGrid, FiTrendingUp, FiActivity, FiClock } from 'react-icons/fi';
import { useTheme } from '../../contexts/ThemeContext';

interface Stats {
  totalUsers?: number;
  totalLeads?: number;
  totalServices?: number;
}

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const [stats, setStats] = useState<Stats>({});
  const [loading, setLoading] = useState(true);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        if (user?.nivel_acesso === '1') {
          const [usersRes, leadsRes, servicesRes] = await Promise.all([
            api.get('/users'),
            api.get('/leads'),
            api.get('/services')
          ]);

          setStats({
            totalUsers: usersRes.data.length,
            totalLeads: leadsRes.data.length,
            totalServices: servicesRes.data.length
          });
        }
      } catch (error) {
        console.error('Erro ao carregar estatísticas:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [user?.nivel_acesso]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <div className="flex flex-col items-center space-y-4">
          <div className="w-12 h-12 border-4 border-neon-green border-t-transparent rounded-full animate-spin"></div>
          <div className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            Carregando estatísticas...
          </div>
        </div>
      </div>
    );
  }

  const recentActivity = [
    { id: 1, title: 'Novo lead registrado', time: '5 minutos atrás', icon: <FiTrendingUp /> },
    { id: 2, title: 'Usuário atualizado', time: '1 hora atrás', icon: <FiActivity /> },
    { id: 3, title: 'Serviço adicionado', time: '2 horas atrás', icon: <FiClock /> },
  ];

  return (
    <div className="space-y-8">
      {/* Welcome Section */}
      <div className={`${isDarkMode ? 'bg-gradient-to-r from-[#1a1a1a] to-[#2a2a2a]' : 'bg-white'} rounded-2xl p-8 shadow-xl ${isDarkMode ? 'border border-gray-800' : 'border border-gray-200'}`}>
        <h1 className={`text-3xl md:text-4xl font-bold ${isDarkMode ? 'bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent' : 'text-gray-900'}`}>
          Bem-vindo, {user?.nome}!
        </h1>
        <p className={`mt-3 text-lg ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          {user?.nivel_acesso === '1'
            ? 'Você tem acesso administrativo ao sistema.'
            : 'Bem-vindo ao seu painel de controle.'}
        </p>
      </div>

      {user?.nivel_acesso === '1' && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Stats Cards */}
          <div className={`${isDarkMode ? 'bg-[#1a1a1a] border-gray-800' : 'bg-white border-gray-200'} rounded-2xl p-6 shadow-xl transform hover:scale-105 transition-all duration-300 border hover:border-neon-green`}>
            <div className="flex items-center space-x-4">
              <div className="p-3 bg-gradient-to-br from-blue-500/20 to-blue-600/20 rounded-xl">
                <FiUsers className="h-6 w-6 text-blue-500" />
              </div>
              <div>
                <div className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Total de Usuários
                </div>
                <div className={`mt-1 text-3xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {stats.totalUsers || 0}
                </div>
              </div>
            </div>
          </div>

          <div className={`${isDarkMode ? 'bg-[#1a1a1a] border-gray-800' : 'bg-white border-gray-200'} rounded-2xl p-6 shadow-xl transform hover:scale-105 transition-all duration-300 border hover:border-neon-green`}>
            <div className="flex items-center space-x-4">
              <div className="p-3 bg-gradient-to-br from-green-500/20 to-green-600/20 rounded-xl">
                <FiTarget className="h-6 w-6 text-green-500" />
              </div>
              <div>
                <div className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Total de Leads
                </div>
                <div className={`mt-1 text-3xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {stats.totalLeads || 0}
                </div>
              </div>
            </div>
          </div>

          <div className={`${isDarkMode ? 'bg-[#1a1a1a] border-gray-800' : 'bg-white border-gray-200'} rounded-2xl p-6 shadow-xl transform hover:scale-105 transition-all duration-300 border hover:border-neon-green`}>
            <div className="flex items-center space-x-4">
              <div className="p-3 bg-gradient-to-br from-purple-500/20 to-purple-600/20 rounded-xl">
                <FiGrid className="h-6 w-6 text-purple-500" />
              </div>
              <div>
                <div className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Total de Serviços
                </div>
                <div className={`mt-1 text-3xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {stats.totalServices || 0}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Recent Activity Section */}
      <div className={`${isDarkMode ? 'bg-[#1a1a1a] border-gray-800' : 'bg-white border-gray-200'} rounded-2xl p-6 shadow-xl border`}>
        <h2 className={`text-xl font-bold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          Atividade Recente
        </h2>
        <div className="space-y-4">
          {recentActivity.map((activity) => (
            <div
              key={activity.id}
              className={`flex items-center space-x-4 p-4 rounded-xl ${
                isDarkMode ? 'bg-[#252525] hover:bg-[#2a2a2a]' : 'bg-gray-50 hover:bg-gray-100'
              } transition-colors duration-200`}
            >
              <div className={`p-2 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
                {activity.icon}
              </div>
              <div className="flex-1">
                <p className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  {activity.title}
                </p>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  {activity.time}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
