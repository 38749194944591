import api from './api';

export interface Lead {
  id: string;
  nome: string;
  email: string;
  telefone?: string;
  mensagem?: string;
  criado_em: string;
}

export const leadsService = {
  getAllLeads: async () => {
    try {
      const response = await api.get('/leads');
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar leads:', error);
      throw error;
    }
  },

  formatPhoneNumber: (phone: string | undefined): string => {
    if (!phone) return '';
    // Remove todos os caracteres não numéricos
    const numbers = phone.replace(/\D/g, '');
    
    // Se o número já começar com 55, não adiciona novamente
    if (numbers.startsWith('55')) {
      return '+' + numbers;
    }
    
    // Adiciona +55 no início
    return '+55' + numbers;
  }
};
