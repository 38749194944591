import React, { useState, useEffect } from 'react';
import { FiPlus, FiEdit2, FiTrash2, FiUsers, FiCheck, FiX } from 'react-icons/fi';
import api from '../../services/api';
import { useTheme } from '../../contexts/ThemeContext';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import LeadSelectionModal from '../../components/LeadSelectionModal';

interface Segment {
  id: string;
  nome: string;
  descricao?: string;
  created_at: string;
}

interface Lead {
  id: string;
  nome: string;
  email: string;
  telefone?: string;
  mensagem?: string;
}

const Segments: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [segments, setSegments] = useState<Segment[]>([]);
  const [selectedSegment, setSelectedSegment] = useState<Segment | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLeadModalOpen, setIsLeadModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [formData, setFormData] = useState({ nome: '', descricao: '' });
  const [message, setMessage] = useState({ type: '', text: '' });
  const [segmentLeads, setSegmentLeads] = useState<Lead[]>([]);

  const fetchSegments = async () => {
    try {
      const response = await api.get('/segments');
      setSegments(response.data);
    } catch (error) {
      console.error('Erro ao buscar segmentos:', error);
      setMessage({ type: 'error', text: 'Erro ao carregar segmentos' });
    }
  };

  useEffect(() => {
    fetchSegments();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (selectedSegment) {
        await api.put(`/segments/${selectedSegment.id}`, formData);
        setMessage({ type: 'success', text: 'Segmento atualizado com sucesso!' });
      } else {
        await api.post('/segments', formData);
        setMessage({ type: 'success', text: 'Segmento criado com sucesso!' });
      }
      setIsModalOpen(false);
      setFormData({ nome: '', descricao: '' });
      fetchSegments();
    } catch (error) {
      console.error('Erro ao salvar segmento:', error);
      setMessage({ type: 'error', text: 'Erro ao salvar segmento' });
    }
  };

  const handleEdit = (segment: Segment) => {
    setSelectedSegment(segment);
    setFormData({ nome: segment.nome, descricao: segment.descricao || '' });
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (!selectedSegment) return;
    try {
      await api.delete(`/segments/${selectedSegment.id}`);
      setMessage({ type: 'success', text: 'Segmento excluído com sucesso!' });
      setIsDeleteModalOpen(false);
      fetchSegments();
    } catch (error) {
      console.error('Erro ao excluir segmento:', error);
      setMessage({ type: 'error', text: 'Erro ao excluir segmento' });
    }
  };

  const handleManageLeads = async (segment: Segment) => {
    setSelectedSegment(segment);
    try {
      const response = await api.get(`/segments/${segment.id}/leads`);
      setSegmentLeads(response.data);
      setIsLeadModalOpen(true);
    } catch (error) {
      console.error('Erro ao buscar leads do segmento:', error);
      setMessage({ type: 'error', text: 'Erro ao carregar leads do segmento' });
    }
  };

  const handleLeadSelection = async (selectedLeads: string[]) => {
    if (!selectedSegment) return;

    try {
      // Encontra os leads que foram removidos (estavam em segmentLeads mas não estão em selectedLeads)
      const removedLeads = segmentLeads
        .map(lead => lead.id)
        .filter(id => !selectedLeads.includes(id));

      // Encontra os leads que foram adicionados (estão em selectedLeads mas não estavam em segmentLeads)
      const addedLeads = selectedLeads
        .filter(id => !segmentLeads.map(lead => lead.id).includes(id));

      // Se há leads para remover, faz a chamada para removê-los
      if (removedLeads.length > 0) {
        await api.delete(`/segments/${selectedSegment.id}/leads`, { 
          data: { leadIds: removedLeads } 
        });
      }

      // Se há leads para adicionar, faz a chamada para adicioná-los
      if (addedLeads.length > 0) {
        await api.post(`/segments/${selectedSegment.id}/leads`, { 
          leadIds: addedLeads 
        });
      }

      setMessage({ type: 'success', text: 'Leads do segmento atualizados com sucesso!' });
      setIsLeadModalOpen(false);

      // Atualiza a lista de leads do segmento
      const response = await api.get(`/segments/${selectedSegment.id}/leads`);
      setSegmentLeads(response.data);
    } catch (error) {
      console.error('Erro ao atualizar leads do segmento:', error);
      setMessage({ type: 'error', text: 'Erro ao atualizar leads do segmento' });
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className={`${isDarkMode ? 'bg-gradient-to-r from-[#1a1a1a] to-[#2a2a2a]' : 'bg-white'} rounded-2xl p-8 shadow-xl mb-8 ${isDarkMode ? 'border-gray-800' : 'border-gray-200'} border`}>
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className={`text-3xl font-bold ${isDarkMode ? 'bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent' : 'text-gray-900'}`}>
              Segmentos
            </h1>
            <p className={`mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              Gerencie seus segmentos de leads
            </p>
          </div>
          <Button
            onClick={() => {
              setSelectedSegment(null);
              setFormData({ nome: '', descricao: '' });
              setIsModalOpen(true);
            }}
            className="flex items-center space-x-2"
          >
            <FiPlus className="w-5 h-5" />
            <span>Novo Segmento</span>
          </Button>
        </div>

        {message.text && (
          <div
            className={`mb-6 rounded-xl p-4 flex items-center space-x-3 ${
              message.type === 'success'
                ? 'bg-green-500/10 border border-green-500 text-green-500'
                : 'bg-red-500/10 border border-red-500 text-red-500'
            }`}
          >
            {message.type === 'success' ? (
              <FiCheck className="w-5 h-5 flex-shrink-0" />
            ) : (
              <FiX className="w-5 h-5 flex-shrink-0" />
            )}
            <span>{message.text}</span>
          </div>
        )}

        <div className="grid grid-cols-1 gap-6">
          {segments.map(segment => (
            <div
              key={segment.id}
              className={`${
                isDarkMode ? 'bg-[#1a1a1a] hover:bg-[#252525]' : 'bg-white hover:bg-gray-50'
              } rounded-xl p-6 shadow-md border ${
                isDarkMode ? 'border-gray-800' : 'border-gray-200'
              } transition-all duration-200`}
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className={`text-xl font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                    {segment.nome}
                  </h3>
                  {segment.descricao && (
                    <p className={`mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      {segment.descricao}
                    </p>
                  )}
                </div>
                <div className="flex space-x-4">
                  <button
                    onClick={() => handleManageLeads(segment)}
                    className={`p-2 rounded-lg ${
                      isDarkMode
                        ? 'hover:bg-gray-800 text-gray-400 hover:text-white'
                        : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
                    } transition-colors duration-200`}
                  >
                    <FiUsers className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => handleEdit(segment)}
                    className={`p-2 rounded-lg ${
                      isDarkMode
                        ? 'hover:bg-gray-800 text-gray-400 hover:text-white'
                        : 'hover:bg-gray-100 text-gray-600 hover:text-gray-900'
                    } transition-colors duration-200`}
                  >
                    <FiEdit2 className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => {
                      setSelectedSegment(segment);
                      setIsDeleteModalOpen(true);
                    }}
                    className={`p-2 rounded-lg ${
                      isDarkMode
                        ? 'hover:bg-red-900/50 text-red-500 hover:text-red-400'
                        : 'hover:bg-red-100 text-red-600 hover:text-red-700'
                    } transition-colors duration-200`}
                  >
                    <FiTrash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal de Criar/Editar Segmento */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={selectedSegment ? 'Editar Segmento' : 'Novo Segmento'}
      >
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="nome"
              className={`block text-sm font-medium ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}
            >
              Nome
            </label>
            <input
              type="text"
              id="nome"
              name="nome"
              value={formData.nome}
              onChange={e => setFormData({ ...formData, nome: e.target.value })}
              className={`mt-1 block w-full rounded-xl shadow-sm ${
                isDarkMode
                  ? 'bg-[#252525] text-white border-gray-700'
                  : 'bg-white text-gray-900 border-gray-300'
              } focus:ring-2 focus:ring-neon-green focus:border-transparent`}
              required
            />
          </div>
          <div>
            <label
              htmlFor="descricao"
              className={`block text-sm font-medium ${
                isDarkMode ? 'text-gray-300' : 'text-gray-700'
              }`}
            >
              Descrição
            </label>
            <textarea
              id="descricao"
              name="descricao"
              value={formData.descricao}
              onChange={e => setFormData({ ...formData, descricao: e.target.value })}
              rows={3}
              className={`mt-1 block w-full rounded-xl shadow-sm ${
                isDarkMode
                  ? 'bg-[#252525] text-white border-gray-700'
                  : 'bg-white text-gray-900 border-gray-300'
              } focus:ring-2 focus:ring-neon-green focus:border-transparent`}
            />
          </div>
          <div className="flex justify-end space-x-4">
            <Button
              type="button"
              onClick={() => setIsModalOpen(false)}
              variant="secondary"
            >
              Cancelar
            </Button>
            <Button type="submit">
              {selectedSegment ? 'Atualizar' : 'Criar'}
            </Button>
          </div>
        </form>
      </Modal>

      {/* Modal de Confirmação de Exclusão */}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Confirmar Exclusão"
      >
        <div className="space-y-6">
          <p className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>
            Tem certeza que deseja excluir este segmento?
          </p>
          <div className="flex justify-end space-x-4">
            <Button
              type="button"
              onClick={() => setIsDeleteModalOpen(false)}
              variant="secondary"
            >
              Cancelar
            </Button>
            <Button
              type="button"
              onClick={handleDelete}
              variant="danger"
            >
              Excluir
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal de Seleção de Leads */}
      <LeadSelectionModal
        isOpen={isLeadModalOpen}
        onClose={() => setIsLeadModalOpen(false)}
        onConfirm={handleLeadSelection}
        currentLeads={segmentLeads}
      />
    </div>
  );
};

export default Segments;
